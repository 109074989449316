export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "是否禁用", prop: "disable", slots: "disable", width: 100 },
    { label: "openid", prop: "openid", width: 300 },
    { label: "手机号", prop: "phone" },
    {
      label: "登录时间",
      prop: "create_time",
      width: 180,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 100,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
